import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, PortableTextBlocks } from 'types/schema';
import PolicyPageLayout from 'components/layout/PolicyPageLayout';

export const pageQuery = graphql`
  query TermsOfUsePageQuery {
    sanityTermsOfUsePage(id: { eq: "-94fa5ee0-3dbd-57fb-9b82-f862db5806a9" }) {
      meta {
        title
        description
      }
      pageTitle
      _rawBody
    }
  }
`;
type QueryData = {
  sanityTermsOfUsePage: BaseSanityPage & {
    _rawBody: PortableTextBlocks;
  };
};

const TermsOfUsePage: React.FC<{ data: QueryData }> = ({ data }) => {
  const {
    meta: { title, description },
    pageTitle,
    _rawBody,
  } = data.sanityTermsOfUsePage;
  return <PolicyPageLayout {...{ title, description, pageTitle }} body={_rawBody} />;
};

export default TermsOfUsePage;
